// app global css in SCSS form

.highlight {
	background-color: #FFFF00;
}


a:link, a:visited {

	&.no-link-decorate {
		text-decoration: none;
		color: inherit;

	}
}

a:hover.no-link-decorate {
	opacity: 70%;
}

.aq-card-bullets {
	p {
		margin: 0 0 8px;
	}
	ul {
		margin-top: 0;
		padding-left: 1.5rem;
		color: #444444;
		list-style: none; // Remove default bullets
//		list-style-position: inside;
		li::before {
			content: "\25CF";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
			color: #C10015; /* Change the color */
			font-weight: bold; /* If you want it to be bold */
			display: inline-block; /* Needed to add space between the bullet and the text */
			width: 1em; /* Also needed for space (tweak if needed) */
			margin-left: -1em; /* Also needed for space (tweak if needed) */
		}
	}
}

a.special-link {
	text-decoration: none;
	color: inherit;
}

a:visited.special-link {
	color: inherit;
}

a:hover.special-link {
	opacity: 0.9;
}

.main-page-bg {
	background-color: #EEEEEE;
}

.doc-page, .doc-page-pad {
	margin-left: auto;
	margin-right: auto;
	max-width: 1200px;
}

.doc-page-pad {
	padding-right: 9px;
	padding-left: 9px;
}

.bg-light {
	background-color: #EEEEEE;
}

h1, h2, h3, h4, h5 {
	margin: 1.25rem 0 0.75rem;
}

.full-height {
	height: 100%;
}

.animate-bounce {
	animation: q-bounce 2s infinite;
}

@keyframes q-bounce {
	0%, 20%, 50%, 80%, 100% {
		transform: translateY(0);
	}
	40%
	{
		transform: translateY(-30px);
	}
	60%
	{
		transform: translateY(-15px);
	}
}


.animate-fade-in0 {
	opacity: 0;
}

.animate-fade-in {
	animation: q-fade-in 1.0s;
	animation-fill-mode: both;
}

@keyframes q-fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}

}
